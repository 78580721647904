import { FC } from 'react'
import styles from './LoadingCircle.module.scss'

const LoadingCircle: FC = () => (
  <div className={styles['loading-circle']}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default LoadingCircle
