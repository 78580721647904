/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosRequestConfig, Method } from 'axios'
import { API_TOKEN, API_URL } from '../constants/env'

/**
 * Make a request with the given options
 *
 * @param options
 */
export default async (options: AxiosRequestConfig) => {
  try {
    const { data: responseData } = await axios(options)

    return {
      success: true,
      data: responseData,
    }
  } catch (err) {
    return {
      success: false,
      // @ts-ignore
      message: err.response ? err.response.data : 'ApiError',
    }
  }
}

/**
 * Returns the AxiosRequestConfig for the given method, url & data
 *
 * @param {Method} method
 * @param {string} url
 * @param {Record<string, unknown> | nul} data
 * @returns {AxiosRequestConfig}
 */
export const getAxiosRequestConfig = (method: Method, url: string, data: Record<string, unknown> | null = null): AxiosRequestConfig => ({
  method,
  baseURL: API_URL,
  url,
  ...(data !== null && { data }),
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${ API_TOKEN }`,
  },
})
