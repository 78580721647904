import { FC, useEffect, useState } from 'react'
import Dashboard from '../pages/Dashboard/Dashboard'
import { CasesMetrics } from '../types/dashboard'
import request, { getAxiosRequestConfig } from '../utils/request'

const AppContainer: FC = () => {
  // Local state
  const [ casesMetrics, setCasesMetrics ] = useState<CasesMetrics|null>(null)

  /**
   * Triggered on first render
   * Fetches cases data on first render & refetches every 10 minutes
   */
  useEffect(() => {
    getCasesMetrics()

    const interval = setInterval(() => {
      getCasesMetrics()
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  /**
   * Get the cases metrics from the API
   */
  const getCasesMetrics = async () => {
    const { data } = await request(getAxiosRequestConfig('GET', '/metrics/cases'))
    setCasesMetrics(data as CasesMetrics)
  }

  return (
    <Dashboard {...{ casesMetrics }} />
  )
}

export default AppContainer
