import { FC } from 'react'
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle'
import { DashboardProps as Props } from './types'
import styles from './Dashboard.module.scss'
import classNames from 'classnames'
import ChopChop from './images/chopchop.png'
import GoingStrong from './images/goingstrong.png'


const Dashboard: FC<Props> = ({ casesMetrics }) => {
  const delta = (casesMetrics?.totalTreatedCasesToday || 0) - (casesMetrics?.totalCasesOpenedToday || 0)

  return (
    <>
      <div className={classNames('row', styles['dashboard-row'])}>
        <div className={classNames('card', styles['number-card'])}>
          <h1>Open cases</h1>
          {casesMetrics ? <p>{casesMetrics.totalOpenCases}</p> : <LoadingCircle />}
        </div>

        <div className={classNames('card', styles['number-card'])}>
          <h1>
            Cases opened today
            <br />
            <small>(created today + reopened today)</small>
          </h1>
          {casesMetrics ? <p>{casesMetrics.totalCasesOpenedToday}</p> : <LoadingCircle />}
        </div>
      </div>

      <div className={classNames('row', styles['dashboard-row'])}>
        <div className={classNames('card', styles['number-card'])}>
          <h1>Cases treated today</h1>
          {casesMetrics ? <p>{casesMetrics.totalTreatedCasesToday}</p> : <LoadingCircle />}
        </div>

        <div className={classNames('card', styles['number-card'])}>
          <h1>
            Delta
            <br />
            <small>(treated today - opened today)</small>
          </h1>
          {casesMetrics ? <p>{delta}</p> : <LoadingCircle />}
        </div>

        <div className={classNames('card', styles['number-card'])}>
          { delta > 0 ? <img src={GoingStrong} alt="Going strong!!" /> : <img src={ChopChop} alt="Chop chop" /> }
        </div>
      </div>
    </>
  )
}

export default Dashboard
